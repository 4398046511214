import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  constructor(private httpClient: HttpClient) {}

  public sendGetRequest() {
    return this.httpClient.get(
      'https://calendar.pure-electric.com.au/api/API.php/getAllInstallation'
    );
    //return this.httpClient.get("http://loc.calendar.com/API.php/getAllInstallation");
  }

  public sendGetRequestWithParam(id) {
    return this.httpClient.get(
      'https://calendar.pure-electric.com.au/api/API.php/getInstallationByInstaller?id=' +
        id
    );
    //return this.httpClient.get("http://loc.calendar.com/API.php/getInstallationByInstaller?id="+id);
  }

  public sendSaveInstallation(param) {
    let url = 'https://calendar.pure-electric.com.au/api/API.php/updateInstallation';
    //let url = "http://loc.calendar.com/API.php/updateInstallation"
    return this.httpClient.post(url, param);
  }

  public sendEmailInstallationSaved(installation_id,invoice_number,role,installer_id) {
    let url = '';
    if(role !='client'){
      url = 'https://suitecrm.pure-electric.com.au/index.php?entryPoint=send_IC_saved&installation_id='+installation_id+'&invoice_number='+invoice_number+'&role='+role+'&installer_id='+installer_id;
    }else{
      url = 'https://suitecrm.pure-electric.com.au/index.php?entryPoint=send_IC_saved&installation_id='+installation_id+'&invoice_number='+invoice_number+'&role='+role;
    }
    return this.httpClient.get(url);
  }
}
