import {
    Directive,
    Renderer2,
    ElementRef,
    OnInit,
    OnDestroy,
    Output,
    EventEmitter,
    Inject,
    Input,
    NgZone
  } from '@angular/core';
  import { DOCUMENT } from '@angular/common';
  import { Observable, Subject } from 'rxjs';
  import { takeUntil } from 'rxjs/operators';
  
  @Directive({
    selector: '[mwlMouseDown]'
  })
  export class MouseDownDirective implements OnInit, OnDestroy {
    @Input() mouseDownListenerDisabled = false;
  
    @Output('mwlMouseDown') mouseDown = new EventEmitter<MouseEvent>(); // tslint:disable-line
  
    private destroy$ = new Subject();
  
    constructor(
      private renderer: Renderer2,
      private elm: ElementRef<HTMLElement>,
      @Inject(DOCUMENT) private document
    ) {}
  
    ngOnInit(): void {
      if (!this.mouseDownListenerDisabled) {
        this.listen()
          .pipe(takeUntil(this.destroy$))
          .subscribe(event => {
            event.stopPropagation();
            this.mouseDown.emit(event);
          });
      }
    }
  
    ngOnDestroy(): void {
      this.destroy$.next();
    }
  
    private listen() {
      return new Observable<MouseEvent>(observer => {
        return this.renderer.listen(this.elm.nativeElement, 'mousedown', event => {
          observer.next(event);
        });
      });
    }
  }
  