import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
  OnInit,
  Input
} from '@angular/core';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
  format
} from 'date-fns';
import {
  DateRangeSelect
} from 'calendar-utils';
import { Subject } from 'rxjs';
import { NgbModal,NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
  CalendarMonthViewDay,
} from 'angular-calendar';
import { elementAt } from 'rxjs/operators';
import { DataService } from '../../data.service';
import { HttpClient } from '@angular/common/http';
import { CompileTemplateMetadata } from '@angular/compiler';
import { Tracing } from 'trace_events';
import moment from 'moment-timezone';

const colors: any = {
  peadmin: {
    primary: '#32cd32',
    secondary: '#91c991'
  },
  plumber: {
    primary: '#1e90ff',
    secondary: '#d1e8ff'
  },
  solar: {
    primary: '#ffff00',
    secondary: '#f9f9b8'
  },
  client: {
    primary: '#ffd800',
    secondary: '#fdf1ba'
  },
  electrician: {
    primary: '#fe6200',
    secondary: '#fabc96'
  },
  battery: {
    primary: '#00BCD4',
    secondary: '#6ccdd9'
  }
};

interface Installation {
  installation_ID: number;
  title: string;
  number: number;
  role: string;
  eventTitle: string;
  installer_ID : string
}

function getTimezoneOffsetString(date: Date): string {
  const timezoneOffset = date.getTimezoneOffset();
  const hoursOffset = String(
    Math.floor(Math.abs(timezoneOffset / 60))
  ).padStart(2, '0');
  const minutesOffset = String(Math.abs(timezoneOffset % 60)).padEnd(2, '0');
  const direction = timezoneOffset > 0 ? '-' : '+';

  return `T00:00:00${direction}${hoursOffset}:${minutesOffset}`;
}
@Component({
  selector: 'ngbd-modal-content',
  template: `
    <div class="modal-header">
    <strong>NOTICE</strong>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div><strong>1.</strong> Color:
        <ul _ngcontent-yvp-c1="" class="note" style="margin-top: 0.25rem;">
          <li _ngcontent-yvp-c1="" class="note-event" style="float:none"><div _ngcontent-yvp-c1="" class="admin-avai"></div>Admin available</li>
          <li _ngcontent-yvp-c1="" class="note-event" style="float:none" *ngIf="['electrician','plumber','peadmin'].includes(role)"><div _ngcontent-yvp-c1="" class="plumber-avai"></div>Plumber available</li>
          <li _ngcontent-yvp-c1="" class="note-event" style="float:none" *ngIf="['solar','peadmin'].includes(role)"><div _ngcontent-yvp-c1="" class="solar-avai"></div>Solar available</li>
          <li _ngcontent-yvp-c1="" class="note-event" style="float:none" *ngIf="['client','peadmin'].includes(role)"><div _ngcontent-yvp-c1="" class="client-avai"></div>Client available</li>
          <li _ngcontent-yvp-c1="" class="note-event" style="float:none" *ngIf="['electrician','plumber','peadmin'].includes(role)"><div _ngcontent-yvp-c1="" class="electrician-avai"></div>Electrician available</li>
          <li _ngcontent-yvp-c1="" class="note-event" style="float:none" *ngIf="['battery','peadmin'].includes(role)"><div _ngcontent-yvp-c1="" class="battery-avai"></div>Battery available</li>
        </ul>
        <div class="clearfix"></div>
      </div>
      <div><strong>2.</strong> Please choose the date available for you, for matching the date, please see color above</div>
      <div><strong>3.</strong> Press on Save Installation.</div>
    </div>
  `
})
export class NgbdModalContent {
  @Input() role;
    constructor(public activeModal: NgbActiveModal) {
  }
}

@Component({
  selector: 'mwl-demo-component',
  // changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['styles.css'],
  templateUrl: 'template.html'
})
export class DemoComponent implements OnInit {
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  modalData: {
    action: string;
    event: CalendarEvent;
  };
  isLoading: boolean;
  eventDropped :any;
  day: any;
  clickedDate: any;
  installation: Installation[] = [];

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-fw fa-pencil"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      }
    },
    {
      label: '<i class="fa fa-fw fa-times"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter(iEvent => iEvent !== event);
        this.handleEvent('Deleted', event);
      }
    }
  ];
  refresh: Subject<any> = new Subject();
  events: CalendarEvent[] = [];
  activeDayIsOpen: boolean = true;
  eventByRole = [];

  constructor(
    private route: ActivatedRoute,
    private modal: NgbModal,
    private dataService_new: DataService,
    private http: HttpClient,
    private modalService: NgbModal
  ) {}
  newStart : any;
  newEnd : any;
  preTitleEvent : string;
  baseEvent : any;

  eventsByRange :CalendarEvent[] = [];
  imageUrl= require('../../assets/images/logo_4.png');
  ngOnInit() {
    let installation_id: string;
    let user_role: string;
    let installer_ID : string;

    this.route.paramMap.subscribe(params => {
      this.open(params.get('user_role'));
      this.isLoading = true;
      installation_id = params.get('installation_id');
      user_role = params.get('user_role');
      installer_ID = params.get('user_id')
      this.getInstallationByID(installation_id, user_role,installer_ID);
      
      switch (user_role) {
        case 'peadmin':
          this.preTitleEvent = 'PE Admin';
          break;
        case 'plumber':
          this.preTitleEvent = 'Plumber';
          break;
        case 'electrician':
          this.preTitleEvent = 'Electrician';
          break;
        case 'solar':
          this.preTitleEvent = 'Solar';
          break;
        case 'battery':
          this.preTitleEvent = 'Battery';
          break;
        case 'client':
          this.preTitleEvent = 'Client';
          break;
      }
      // setTimeout(()=>{ this.isLoading = true }, 500)
      if(this.eventsByRange.length == 0){
        this.addEventRange();
      }
    });
  }

  open(user_role) {
    const modalRef = this.modalService.open(NgbdModalContent);
    modalRef.componentInstance.role = user_role;
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {

    // THIENPB update logic set available for role clients
    if( this.installation[0].role != 'peadmin'){
      let event_admin = [];
      this.events.filter(
        event => { if(event.color.primary == '#32cd32'){ event_admin.push(format(event.start, 'yyyy-MM-dd') + getTimezoneOffsetString(event.start))} } 
      )
      if(event_admin.length > 0){
        if(!event_admin.includes( format(date, 'yyyy-MM-dd') + getTimezoneOffsetString(date))){
        alert('You not able to choose this date. Please choose the date that Admin already setup');return;
        }
      }
    }
    if (isSameMonth(date, this.viewDate)) {
      let eventToDelete: any;
      switch (this.installation[0].role) {
        case 'peadmin':
          eventToDelete = events.filter(
            ({ color }) => color.primary == '#32cd32'
          );
          break;
        case 'plumber':
          eventToDelete = events.filter(
            ({ color }) => color.primary == '#1e90ff'
          );
          break;
        case 'solar':
          eventToDelete = events.filter(
            ({ color }) => color.primary == '#ffff00'
          );
          break;
        case 'electrician':
          eventToDelete = events.filter(
            ({ color }) => color.primary == '#fe6200'
          );
          break;
        case 'battery':
          eventToDelete = events.filter(
            ({ color }) => color.primary == '#00BCD4'
          );
          break;
        case 'client':
          eventToDelete = events.filter(
            ({ color }) => color.primary == '#ffd800'
          );
          break;
      }
      if (eventToDelete.length <= 0) {
        let clicked_Date = [date];
        this.generateEventByRole(
          this.installation[0].number,
          JSON.stringify(clicked_Date),
          this.installation[0].role
        );
        this.events = [...this.events, ...this.eventByRole];
      }
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }
  getDaysArray (start, end) {
    for(var arr=[],dt=start; dt<=end; dt.setDate(dt.getDate()+1)){
        arr.push(new Date(dt));
    }
    return arr;
  };

  setRangeDate(state='create') {
    let temp = [];
   this.eventsByRange.forEach(element =>{
     console.log(new Date(element.start).getTime(),new Date(element.end).getTime())
    if(new Date(element.start).getTime() > new Date(element.end).getTime() && new Date(element.end).getTime() != 0){
      alert('Please select Date Start < Date End.');
      return false;
    }
    if(state == 'create'){
     // THIENPB update logic set available for role when select rangeDate
     if( this.installation[0].role != 'peadmin'){
        let event_admin = [];
        this.events.filter(
          event => { if(event.color.primary == '#32cd32'){ event_admin.push(format(event.start, 'yyyy-MM-dd') + getTimezoneOffsetString(event.start))} } 
        )
        if(event_admin.length > 0){
            if(!event_admin.includes( format(element.start, 'yyyy-MM-dd') + getTimezoneOffsetString(element.start))){
              alert('You not able to choose this range of date. Please choose the range of date that Admin already setup');return;
            }
            if(typeof element.end != undefined && element.end != null){
              if(!event_admin.includes( format(element.end, 'yyyy-MM-dd') + getTimezoneOffsetString(element.end))){
                alert('You not able to choose this range of date. Please choose the range of date that Admin already setup');return;
              }
            }
        }
      }
    }

    let newEvent = [];
    var daylist = this.getDaysArray(element.start ? new Date(element.start) : new Date(element.end),element.end ? new Date(element.end) : new Date(element.start) );
    newEvent = daylist.map((v)=> format(v, 'yyyy-MM-dd') + getTimezoneOffsetString(v));

    this.generateEventByRole(
      this.installation[0].number,
      JSON.stringify(newEvent),
      this.installation[0].role
    );

    
    this.eventByRole.filter(
      event => {
       let check = false;
       for(let i = 0 ; i < this.baseEvent.length; i++){
        if( format(this.baseEvent[i].start, 'yyyy-MM-dd') == format(event.start, 'yyyy-MM-dd') && this.baseEvent[i].color == event.color){
          check = true;
        }
       }
      if(check == false){
       temp.push(event);
      }
    });
    
   })
   this.events = [...this.baseEvent,...temp];
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map(iEvent => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    return;
  }

  addEventRange(startDate: Date = null, endDate: Date = null): void {
    let title_ = '';
    let user_role = '';
    let number = '';
    this.route.paramMap.subscribe(params => {
      user_role = params.get('user_role');
      number = params.get('installation_id')
    });
    switch (user_role) {
      case 'peadmin':
        title_ = 'PE Admin';
        break;
      case 'plumber':
        title_ = 'Plumber';
        break;
      case 'solar':
        title_ = 'Solar';
        break;
      case 'electrician':
        title_ = 'Electrician';
        break;
      case 'battery':
        title_ = 'Battery';
        break;
      case 'client':
        title_ = 'Client';
        break;
    }
    this.eventsByRange = [
      ...this.eventsByRange,
      {
        title: title_ + ' available for installation for invoice #' + number,
        start: null,
        end: null,
        color: colors.peadmin,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true
        }
      }
    ];
  }

  saveInstallation() {
    let pe_available_date = [],
      plumber_available_date = [],
      electric_available_date = [],
      solar_available_date = [],
      client_available_date = [],
      installation_date_dealed = [];

    switch (this.installation[0].role) {
      case 'peadmin':
        this.events.filter(event =>
          event.color.primary == '#32cd32'
            ? pe_available_date.push(
                format(event.start, 'yyyy-MM-dd') +
                  getTimezoneOffsetString(event.start)
              )
            : ''
        );
        break;
      case 'plumber':
        this.events.filter(event =>
          event.color.primary == '#1e90ff'
            ? plumber_available_date.push(
                format(event.start, 'yyyy-MM-dd') +
                  getTimezoneOffsetString(event.start)
              )
            : ''
        );
        break;
      case 'electrician':
        this.events.filter(event =>
          event.color.primary == '#fe6200'
            ? electric_available_date.push(
                format(event.start, 'yyyy-MM-dd') +
                  getTimezoneOffsetString(event.start)
              )
            : ''
        );
        break;
      case 'solar':
        this.events.filter(event =>
          event.color.primary == '#ffff00'
            ? solar_available_date.push(
                format(event.start, 'yyyy-MM-dd') +
                  getTimezoneOffsetString(event.start)
              )
            : ''
        );
        break;
      case 'battery':
        this.events.filter(event =>
          event.color.primary == '#00BCD4'
            ? solar_available_date.push(
                format(event.start, 'yyyy-MM-dd') +
                  getTimezoneOffsetString(event.start)
              )
            : ''
        );
        break;
      case 'client':
        this.events.filter(event =>
          event.color.primary == '#ffd800'
            ? client_available_date.push(
                format(event.start, 'yyyy-MM-dd') +
                  getTimezoneOffsetString(event.start)
              )
            : ''
        );
        break;
      case 'date_dealed':
        break;
    }

    let param = {
      pe_available_date: JSON.stringify(pe_available_date),
      plumber_available_date: JSON.stringify(plumber_available_date),
      electric_available_date: JSON.stringify(electric_available_date),
      solar_available_date: JSON.stringify(solar_available_date),
      client_available_date: JSON.stringify(client_available_date),
      installation_date_dealed: JSON.stringify(installation_date_dealed),
      updated_by: '',
      id: this.installation[0].installation_ID,
      role: this.installation[0].role,
      installer_ID : this.installation[0].installer_ID,
    };
    this.dataService_new.sendSaveInstallation(param).subscribe({
      next(data){
        data => param;
        alert(data['message']);
      }
    });

    if(this.installation[0].role != 'client' && this.installation[0].role != 'peadmin'){
      this.dataService_new.sendEmailInstallationSaved(this.installation[0].installation_ID,this.installation[0].number,this.installation[0].role,this.installation[0].installer_ID).subscribe((data: any[]) => {});
    }else if(this.installation[0].role == 'client'){
      this.dataService_new.sendEmailInstallationSaved(this.installation[0].installation_ID,this.installation[0].number,this.installation[0].role,'').subscribe((data: any[]) => {});
    }
  }

  deleteEvent(eventToDelete: CalendarEvent,colorByRole) {
    if(eventToDelete.color.primary == colorByRole.primary){
      this.events = this.events.filter(event => event !== eventToDelete);
    }else{
      alert('Access denied');
    }
  }

  deleteEventByRange(eventToDelete: CalendarEvent) {
    this.eventsByRange = this.eventsByRange.filter(event => event !== eventToDelete);
    if(this.eventsByRange.length <= 0){
      this.addEventRange();
    }
    this.setRangeDate('delete');
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  generateEventByRole(number, event_date, role) {
    this.eventByRole = [];
    if (JSON.parse(event_date).length <= 0) return;
    event_date = JSON.parse(event_date).sort(function(a, b) {
      return new Date(a).getTime() - new Date(b).getTime();
    });

    for (var i = 0; i < event_date.length; i++) {
      let title_ = '';
      switch (role) {
        case 'peadmin':
          title_ = 'PE Admin';
          break;
        case 'plumber':
          title_ = 'Plumber';
          break;
        case 'solar':
          title_ = 'Solar';
          break;
        case 'electrician':
          title_ = 'Electrician';
          break;
        case 'client':
          title_ = 'Client';
          break;
        case 'battery':
          title_ = 'Battery';
          break;
      }
      console.log(title_,event_date[i]);
      let localeDate   = moment.tz(event_date[i],Intl.DateTimeFormat().resolvedOptions().timeZone);
      this.eventByRole[i] = {
        title: title_ + ' available for installation of invoice #' + number,
        start: startOfDay(new Date(localeDate.clone().tz("Australia/Melbourne").format('YYYY-MM-DD'))),
        end: null,
        color: colors[role],
        draggable: false,
        resizable: {
          beforeStart: true,
          afterEnd: true
        },
      };
      if(role == this.installation[0].role){
          this.eventByRole[i].actions = [
          {
            label: '<i  class="fa fa-fw fa-times custom_color"></i>',
            onClick: ({ event }: { event: CalendarEvent }): void => this.deleteEvent(event,colors[this.installation[0].role])
          }
        ]
      }
    }
  }

  getInstallationByID(id, role,installer_ID) {
    this.events = [];
    this.dataService_new
      .sendGetRequestWithParam(id)
      .subscribe((data: any[]) => {
        this.installation[0] = {
          installation_ID: id,
          title: 'Installation for  #' + data['message']['invoice_number']+' - '+data['message']['invoice_title'],
          number: data['message']['invoice_number'],
          role: role,
          installer_ID : installer_ID,
          eventTitle : this.preTitleEvent + ' available for installation for invoice #' + data['message']['invoice_number'],
        };
        if (
          data['message']['pe_available_date'] != null &&
          data['message']['pe_available_date'] != ''
        ) {
          this.generateEventByRole(
            data['message']['invoice_number'],
            data['message']['pe_available_date'],
            'peadmin'
          );
          this.events = [...this.events, ...this.eventByRole];
        }
        if (
          data['message']['plumber_available_date'] != null &&
          data['message']['plumber_available_date'] != '' && ['electrician','plumber','peadmin'].includes(this.installation[0].role)
        ) {
          this.generateEventByRole(
            data['message']['invoice_number'],
            data['message']['plumber_available_date'],
            'plumber'
          );
          this.events = [...this.events, ...this.eventByRole];
        }
        if (
          data['message']['electric_available_date'] != null &&
          data['message']['electric_available_date'] != '' && ['electrician','plumber','peadmin'].includes(this.installation[0].role)
        ) {
          this.generateEventByRole(
            data['message']['invoice_number'],
            data['message']['electric_available_date'],
            'electrician'
          );
          this.events = [...this.events, ...this.eventByRole];
        }
        if (
          data['message']['solar_available_date'] != null &&
          data['message']['solar_available_date'] != '' && (this.installation[0].role == 'solar' || this.installation[0].role == 'peadmin')
        ) {
          this.generateEventByRole(
            data['message']['invoice_number'],
            data['message']['solar_available_date'],
            'solar'
          );
          this.events = [...this.events, ...this.eventByRole];
        }

        if (
          data['message']['battery_available_date'] != null &&
          data['message']['battery_available_date'] != '' && (this.installation[0].role == 'battery' || this.installation[0].role == 'peadmin')
        ) {
          this.generateEventByRole(
            data['message']['invoice_number'],
            data['message']['battery_available_date'],
            'solar'
          );
          this.events = [...this.events, ...this.eventByRole];
        }

        if (
          data['message']['client_available_date'] != null &&
          data['message']['client_available_date'] != '' && (this.installation[0].role == 'client' || this.installation[0].role == 'peadmin')
        ) {
          this.generateEventByRole(
            data['message']['invoice_number'],
            data['message']['client_available_date'],
            'client'
          );
          this.events = [...this.events, ...this.eventByRole];
        }
        console.log(this.events);

        this.baseEvent = this.events
      });
  }

  dayMouseDown({ date, events }: { date: Date; events: CalendarEvent[] }): void {
   
    event.preventDefault();
  }

  dayMouseMove({ date, events }: { date: Date; events: CalendarEvent[] }): void {

    event.preventDefault();
  }

  dayMouseUp({ date, events }: { date: Date; events: CalendarEvent[] }): void {
  
    event.preventDefault();
  }

  dayRangeSelected(rangeDay){
 
    event.preventDefault();

    if(new Date(rangeDay.rangeDay.startDate.date).getTime() == new Date(rangeDay.rangeDay.endDate.date).getTime())return;
    let newEvent = [];

    let daylist:any;
    let temp = []
    
    // THIENPB update logic set available for role when select rangeDate
    if( this.installation[0].role != 'peadmin'){
      let event_admin = [];
      this.events.filter(
        event => { if(event.color.primary == '#32cd32'){ event_admin.push(format(event.start, 'yyyy-MM-dd') + getTimezoneOffsetString(event.start))} } 
      )
      if(event_admin.length > 0){
         if(!event_admin.includes( format(rangeDay.rangeDay.startDate.date, 'yyyy-MM-dd') + getTimezoneOffsetString(rangeDay.rangeDay.startDate.date)) || 
            !event_admin.includes( format(rangeDay.rangeDay.endDate.date, 'yyyy-MM-dd') + getTimezoneOffsetString(rangeDay.rangeDay.endDate.date))){
            alert('You not able to choose this range of date. Please choose the range of date that Admin already setup');return;
         }
      }
    }

    if(new Date(rangeDay.rangeDay.startDate.date).getTime() < new Date(rangeDay.rangeDay.endDate.date).getTime()){
       daylist = this.getDaysArray(rangeDay.rangeDay.startDate.date,rangeDay.rangeDay.endDate.date);
    }else{
      daylist = this.getDaysArray(rangeDay.rangeDay.endDate.date,rangeDay.rangeDay.startDate.date);
    }
    newEvent = daylist.map((v)=> format(v, 'yyyy-MM-dd') + getTimezoneOffsetString(v));

    this.generateEventByRole(
      this.installation[0].number,
      JSON.stringify(newEvent),
      this.installation[0].role
    );

    this.eventByRole.filter(
      event => {
       let check = false;
       for(let i = 0 ; i < this.events.length; i++){
        if( format(this.events[i].start, 'yyyy-MM-dd') == format(event.start, 'yyyy-MM-dd') && this.events[i].color == event.color){
          check = true;
        }
       }
      if(check == false){
       temp.push(event);
      }
    });
    this.events = [...this.events,...temp];
  }
}
